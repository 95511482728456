function checkLogin() {
	let data = new FormData();
	data.append("action", "check_login_status");

	$.ajax({
		url: bluetalk_scripts.ajax_url,
		type: "POST",
		data: data,
		cache: false,
		processData: false,
		contentType: false,
		error: function (data) {
			console.error(data);
		},
		complete: function (data) {
			try {
				const json = JSON.parse(data.responseText);
				const isLoggedIn = json.isLoggedIn;

				const placeholderMenuItem = document.querySelector(
					".menu .login-placeholder"
				);
				const loginMenuItem =
					document.querySelector(".menu .menu-login");
				const logoutMenuItem =
					document.querySelector(".menu .menu-logout");

				placeholderMenuItem.classList.add("hide");

				if (isLoggedIn) {
					loginMenuItem.classList.add("hide");
					logoutMenuItem.classList.remove("hide");
				} else {
					loginMenuItem.classList.remove("hide");
					logoutMenuItem.classList.add("hide");
				}
			} catch (error) {}
		},
	});
}

function checkVideoLogin() {
	const videoWrapper = document?.querySelector(
		".article-content .video-wrapper"
	);
	const loginOverlay = videoWrapper?.querySelector(".login-overlay");
	if (!videoWrapper || !loginOverlay) {
		return;
	}

	let data = new FormData();
	data.append("action", "video_check_login_status");

	$.ajax({
		url: bluetalk_scripts.ajax_url,
		type: "POST",
		data: data,
		cache: false,
		processData: false,
		contentType: false,
		error: function (data) {
			console.error(data);
		},
		complete: function (data) {
			videoWrapper.innerHTML = data.responseText;
		},
	});
}

checkLogin();
checkVideoLogin();
