import Glide from "@glidejs/glide";
let glide = null;

function initFeaturedTalkSlider(id) {
	glide = new Glide("#" + id, {
		type: "carousel",
		startAt: 0,
		bound: false,
		gap: 50,
		perView: 1,
	});

	glide.mount();
}

export function moveGlide(whichWay) {
	glide.go(whichWay);
}

window.initFeaturedTalkSlider = initFeaturedTalkSlider;
