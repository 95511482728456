import { moveGlide } from "./_featured-talk-slider-configuration";

let featuredTalk = document.querySelectorAll(".featured-talk");

if (featuredTalk.length > 0) {
	featuredTalk.forEach((slider, index) => {
		let arrowPrev = slider.querySelector(".glide__arrows button.prev");
		let arrowNext = slider.querySelector(".glide__arrows button.next");

		arrowPrev.addEventListener("click", (e) => {
			moveGlide("<");
		});

		arrowNext.addEventListener("click", (e) => {
			moveGlide(">");
		});
	});
}
